import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'
import { UpdatedPokerFaq } from './faqs-list-interface'
import FAQ from './FAQ'
import * as links from '../internal-links'
import useFaqExpansion from '../../hooks/useFaqExpansion'
import './FAQs.scss'

interface Props {
  title?: string
  index?: number
  pokerfaqs: UpdatedPokerFaq[]
}

const pokerfaqLinks = [
  { title: 'Generic', link: links.POKER_FAQS, range: [1, 31] },
  { title: 'Poker Strategies', link: links.POKER_FAQS_STRATEGIES, range: [32, 35] },
  { title: 'Poker Tournaments', link: links.POKER_FAQS_TOURNAMENTS, range: [36, 39] },
  { title: 'Poker Software', link: links.POKER_FAQS_SOFTWARE, range: [40, 43] },
  { title: 'Poker Download', link: links.POKER_FAQS_POKER_DOWNLOAD, range: [44, 48] },
  { title: 'Payments Bonuses', link: links.POKER_FAQS_POKER_PAYMENTSBONUSES, range: [49, 61] },
  { title: 'Help', link: links.POKER_FAQS_HELP, range: [62, 68] },
]

const PokerFAQ: React.FC<Props> = ({ title, pokerfaqs }) => {
  const [activeCategory, setActiveCategory] = useState(pokerfaqLinks[0])  // Default to the first category
  const { expandedFaq, setExpandedFaq } = useFaqExpansion()

  // Filter FAQs based on the active category
  const filteredFaqs = pokerfaqs.filter((_, i) => {
    const faqIndex = i + 1 // FAQ index starts from 1
    return (
      faqIndex >= activeCategory.range[0] &&
      faqIndex <= activeCategory.range[1]
    )
  })
  const handleCategoryClick = (category: React.SetStateAction<{ title: string; link: string; range: number[] }>) => {
    setActiveCategory(category)
  }

  useEffect(() => {
    const selectedIndex = pokerfaqLinks.findIndex(f => f.link === window.location.pathname);
    if (selectedIndex !== -1) {
      setActiveCategory(pokerfaqLinks[selectedIndex]);  // Set the active category based on the URL
    }
  }, []);

 

  useEffect(() => {
    const selectedIndex = pokerfaqLinks.findIndex(f => f.link === window.location.pathname)
    if (selectedIndex > 2) {
      const el = window.document.getElementById(`id${selectedIndex}`)
      const panel = window.document.getElementById('panel-container')
      if (el && panel) {
        panel.scrollTo({
          top: 0,
          left: el.offsetLeft - 22,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  return (
    <div id="FAQs">
      <Container>
        <Row>
          <Col>
            <h1 className="page-title">
              {title ? (
                <>
                  <span>Poker FAQs</span>
                  <span className="faq-title-desktop">: {title}</span>
                </>
              ) : (
                <>
                  <span className="faq-title-mobile">Poker FAQs</span>
                  <span className="faq-title-desktop">FAQs About Online Poker Game</span>
                </>
              )}
            </h1>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col id="side-panel" className="side-panel" md={3}>
            <div id="panel-container">
              {pokerfaqLinks.map((l, i) => (
                <Link
                  to={l.link}
                  key={l.link}
                  className={l.title === activeCategory.title ? 'highlight' : ''}
                  id={`id${i}`}
                  onClick={() => handleCategoryClick(l)}
                >
                  {l.title}
                </Link>
              ))}

              <Link to={links.HELP_CENTRE} className="btn btn-help">
                HELP CENTER
              </Link>
              <Link to={links.CONTACT_US} className="btn">
                CONTACT US
              </Link>
            </div>
          </Col>

          <Col className="faqs">
            {filteredFaqs.map((f, i) => (
              <React.Fragment key={f.question}>
                <FAQ
                  question={f.question}
                  answer={f.answer}
                  expanded={expandedFaq === i}
                  setExpanded={setExpandedFaq}
                  questinNumber={i}
                />
                {i !== filteredFaqs.length - 1 ? <div className="separator" /> : null}
              </React.Fragment>
            ))}
          </Col>
        </Row>

        <Row>
          <Col className="help" md={3}>
            <div>Couldn’t find what you were looking for?</div>
            <Link to={links.HELP_CENTRE}>HELP CENTER</Link>
            <Link to={links.CONTACT_US}>CONTACT US</Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PokerFAQ;
